import "./App.css";
import Header from "./components/Header/Header";
import Case_detail from "./components/Case_detail/Case_detail";
import Footer from "./components/Footer/Footer";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Main_home from "./components/Main_home";
import Case_detail2 from "./components/Case_detail2";
import Case_detail3 from "./components/Case_detail3";
import ArccoinARC from "./components/ArccoinARC";
import Sainft from "./components/Sainft";
import GuildQbQuest from "./components/GuildQbQuest";
import Wireswap from "./components/Wireswap";
import Nftxpress from "./components/Nftxpress";
import ArchieScan from "./components/ArchieScan";
import ArchienekoNft from "./components/ArchienekoNft";
import Portfolio from "./components/Portfolio/Portfolio";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Main_home />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Case_detail" element={<Case_detail/>} />
        <Route path="/Case_detail2" element={<Case_detail2/>} />
        <Route path="/Case_detail3" element={<Case_detail3/>} />
        <Route path="/Sainft" element={<Sainft/>} />
        <Route path="/GuildQbQuest" element={<GuildQbQuest/>} />
        <Route path="/Wireswap" element={<Wireswap/>} />
        <Route path="/Nftxpress" element={<Nftxpress/>} />
        <Route path="/ArchieScan" element={<ArchieScan/>} />
        <Route path="/ArchienekoNft" element={<ArchienekoNft/>} />
        <Route path="/ArccoinARC" element={<ArccoinARC/>} />
      </Routes>
      <Footer/>
    </BrowserRouter>
    </div>
  );
}

export default App;
