import React from 'react'
import './GetA.css'
import one from '../assets/one.png'
import two from '../assets/two.png'
import three from '../assets/three.png'
import four from '../assets/four.png'
import five from '../assets/five.png'
import six from '../assets/six.png'
import line from '../assets/forstline.png'
import twoo from "../assets/ch.png"
import v from '../assets/v.png'
import w from '../assets/w.png'
import x from '../assets/x.png'
import dasa from '../assets/dsadasd.png'
import y from '../assets/y.png'

function GetA() {
  return (
    <>

   
    <div className="container my-5 " style={{paddingTop:"5rem"}}>
    <div className='section-heading text-start'>

  

<h1 className=''>
        <b className=''>
    Get a  <span className=' textcolor'> comprehensive </span> <br />
approach to <span className='textcolor'>  
<span className='yai'>
blockchain
</span>

<br />

<span className='yaki'>
<span className='yaki'>
development 
</span>
</span>
</span>

</b>
</h1>
</div>

        <div className="row ">
            <div className="col-12  col-md-6 mt-5 ">

<div className='forboder  done onhover onmobile'>

            <div className=''>
          
            <h5 className='heiding text-start'> Exchange
            
            <br />
             platforms  </h5>
            <p className='text-start phraheight'> 
            Applying our experience from similar projects launched, the team provide the development of an easy-to-use Exchange Platform with efficient real-time trading systems for digital assets. Without any functional restrictions. </p>
</div>
            <img src={one} className='w-50 picture '   alt="" />
            {/* <img src={twoo} className=' w-50 hver_img ' alt="" /> */}
            <img src={twoo} className=' w-50 hver_img ' alt="" />
            </div>
        </div>

         <div className="col-12 col-md-6 mt-5 ">

<div className='forboder done onhover onmobile'>

            <div className=' '>
          
            <h5 className='heiding text-start'>  NFT 
            
            <br />
            Marketplace   </h5>  
            <p className='text-start phraheight'> 
            We help our customers to launch their own NFT marketplace that offers a flawless buying experience and an opportunity for artists to sell their content on the customer's platform.  </p>
</div>
            <img src={two} className='w-50 picture' alt="" />
            <img src={v} className='w-50 vwli' alt="" />
            </div>
        </div>       

        </div>

        {/* facke code    */}
{/* 
        <div className="row ">


        <div className="col-12 col-md-6 mt-5 ">

<div className='forboder done onhover onmobile'>

            <div className=' '>
          
            <h5 className='heiding text-start'>  NFT 
            
            <br />
            Marketplace   </h5>  
            <p className='text-start phraheight'> 
            We help our customers to launch their own NFT marketplace that offers a flawless buying experience and an opportunity for artists to sell their content on the customer's platform.  </p>
</div>
            <img src={two} className='w-50 picture' alt="" />
            <img src={v} className='w-50 vwli' alt="" />
            </div>
        </div>  
            <div className="col-12  col-md-6 mt-5 ">

            

<div className='forboder bg-dark text-white done onhover onmobile'>

            <div className=''>
          
            <h5 className='heiding text-start'> Exchange
            
            <br />
             platforms  </h5>
            <p className='text-start phraheight'> 
            Applying our experience from similar projects launched, the team provide the development of an easy-to-use Exchange Platform with efficient real-time trading systems for digital assets. Without any functional restrictions. </p>
</div>
            <img src={twoo} className='w-50 picture'   alt="" />
            <img src={ one} className=' w-50 hver_img ' alt="" />
            </div>
        </div>

              

        </div>


        <div className="row ">
            <div className="col-12  col-md-6 mt-5 ">

<div className='forboder bg-dark text-white done onhover onmobile'>

            <div className=''>
          
            <h5 className='heiding text-start'> Exchange
            
            <br />
             platforms  </h5>
            <p className='text-start phraheight'> 
            Applying our experience from similar projects launched, the team provide the development of an easy-to-use Exchange Platform with efficient real-time trading systems for digital assets. Without any functional restrictions. </p>
</div>
            <img src={twoo} className='w-50 picture'   alt="" />
            <img src={ one} className=' w-50 hver_img ' alt="" />
            </div>
        </div>

         <div className="col-12 col-md-6 mt-5 ">

<div className='forboder done onhover onmobile'>

            <div className=' '>
          
            <h5 className='heiding text-start'>  NFT 
            
            <br />
            Marketplace   </h5>  
            <p className='text-start phraheight'> 
            We help our customers to launch their own NFT marketplace that offers a flawless buying experience and an opportunity for artists to sell their content on the customer's platform.  </p>
</div>
            <img src={two} className='w-50 picture' alt="" />
            <img src={v} className='w-50 vwli' alt="" />
            </div>
        </div>       

        </div> */}

        {/* face code end    */}



        {/* real code    */}

        <div className="row">
            <div className="col-md-6 col-12 mt-5 ">

<div className='forboder done onhover onmobile'>

            <div className=' '>
          
            <h5 className='heiding text-start'>  Smart contract 
            
            <br />
             Development </h5>
            <p className='text-start phraheight'> 
            Creation of secure and fast smart contacts. The Phenomenon development team will help to think over and implement a smart contract of any complexity and with any logic.   </p>
</div>
            <img src={three} className='w-50  picture' alt="" />
            <img src={w} className='w-50  wwali   ' alt="" />
            </div>
        </div>

        <div className="col-md-6 col-12 mt-5 ">

<div className='forboder onhover done onmobile'>

            <div className=' '>
          
            <h5 className='heiding text-start'>  NFT 
            
            <br />
            Marketplace   </h5>  Token Development 
            <p className='text-start phraheight'> 
            We help our customers to launch their own NFT marketplace that offers a flawless buying experience and an opportunity for artists to sell their content on the customer's platform.  </p>
</div>
            <img src={four} className='w-50 picture' alt="" />
            <img src={x} className='w-50 xwali' alt="" />
            </div>
        </div>      

        </div>

        <div className="row">
           

        <div className="col-md-6 col-12 mt-5 ">

<div className='forboder onhover done onmobile'>

            <div className=' '>
          
            <h5 className='heiding text-start'>   DAO 
            
            <br />
            Development    </h5>  
            <p className='text-start phraheight'> 
            Blockchain provides a secure shared system with rich features. Our team has vast expertise in cost-effective DAO blockchain development, addressing challenges faced by traditional centralized businesses.   </p>
</div>
            <img src={five} className='w-50 picture' alt="" />
            <img src={dasa} className='w-50 dasawali' alt="" />
            </div>
        </div>   

         <div className="col-md-6 col-12 mt-5 ">

<div className='forboder onhover done onmobile'>

            <div className=' '>
          
            <h5 className='heiding text-start'>   Consulting 
            
            <br />
  </h5>
            <p className='text-start phraheight'> 
            Consulting from Phenomenon is an analysis of an idea, its effectiveness, and its advantage over competitors. The Phenomenon team helps clients to think over and create a product of any complexity. From idea to product support after launch.    </p>
</div>
            <img src={six} className='w-50 picture' alt="" />
            <img src={y} className='w-50 ywali' alt="" />
            </div>
        </div>   

        </div>
       

        {/* real code end    */}
    </div>
    </>

  )
}

export default GetA