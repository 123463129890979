import React from 'react'
import "./TechnologyStack_type.css"

function TechnologyStack_type() {
  return (
    <div>
        <div className="row justify-content-evenly " style={{marginTop:"4rem"}}>
      <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/Binance-Smart-Chain-0.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Binance Chain</h5>
        </div>
      </div>

      <div className="col-lg-5 meg2">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/Binance-Smart-Chain-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Polygon</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/polkadot-new-dot-logo-3.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Polkadot</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/solana-sol-logo-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Solana</h5>
        </div>
      </div>
      </div>
    </div>
  )
}

export default TechnologyStack_type
